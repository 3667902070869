body {
  background: #f5f6f6 !important;
}
#loadings,
#loading {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  z-index: 99999;
  text-align: center;
  /* vertical-align: middle; */
  width: 100%;
  height: 100%;
  background: #e5eff1;
  line-height: 100vh;
}
#loadings img,
#loading img {
  width: 200px;
}
.login-error {
  font-size: 12px;
  text-align: center;
  color: red;
  padding: 10px 0;
}
.errorshow {
  font-size: 12px;
  text-align: center;
  color: red;
}
.errorhide {
  display: none;
}

.errorshows input {
  border: 1px solid red !important;
}
.errorhides input {
  border: 1px solid #ededed !important;
}
.form .form-control.errorshow2 {
  border: 1px solid red !important;
}
.form .form-control.errorhide2 {
  border: 1px solid #ededed !important;
}
.header {
  width: 100%;

  border-bottom: 0.5px solid #cfcfcf;
  background: #fff;
  min-width: 100%;
  background-color: #ffffff;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;

  flex-shrink: 0;
  flex-direction: column;
}
.topbar {
  min-height: 46px;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}
.inputfocus {
  outline-style: none !important;
  outline: none;
}
.branding {
  height: 100%;
  display: inherit;
  position: relative;
  align-items: center;
  /* margin-right: 60px; */
}
.branding .logo {
  display: inherit;
  width: 112px;
  padding: 2px 0;
  /* margin-left: 30px; */
}
.branding .logo img {
  width: 90%;
}
* {
  outline: none;
  outline-style: none;
  box-sizing: border-box;
}
.Logotext {
  height: 46px;
  display: flex;
  align-items: center;
}
.LogotextLbl {
  color: #414141;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.02em;
  max-width: 200px;
}
.BrandseparatorBox {
  display: flex;
}
.BrandseparatorBor {
  width: 20px;
  height: 30px;
  border-right: 1px solid #dedede;
}
.BrandseparatorSpac {
  width: 20px;
}
.Navigation {
  flex: 1;
  /* display: flex; */
  justify-content: left;
}
.Navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1;
  display: flex;
  justify-content: left;
  max-width: 870px;
  margin: auto;
}
.Navigation ul li {
  position: relative;
  padding-right: 10px;
  display: flex;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  justify-content: flex-start;
  text-decoration: none;
}
.Navigation ul li.active a {
  color: #00ab5b;
}
.Navigation ul li:last-child {
  padding-right: 0px;
}
.Navigation ul li a {
  font-size: 14px;
  line-height: 32px;
  padding: 0 10px 0 10px;
  font-family: 'Open Sans', 'Arial', 'Helvetica', sans-serif;
  letter-spacing: 0.02em;
  text-decoration: none;
  cursor: pointer;
  color: #414141;
  white-space: nowrap;
}

.UserLogin {
  height: 100%;
  flex-shrink: 0;
  align-items: center;
  margin-left: auto;
  position: relative;
  width: 165px;
  text-align: center;
}

.UserLoginBox {
  display: inline-block;
  position: relative;
}
.UserLoginBox::before {
  content: '';
  width: 100%;
  height: 9px;
  position: absolute;
  left: 0px;
  bottom: -9px;
}
.UserLoginLbl {
  letter-spacing: 0px;
  color: #000;
  font-size: 12px;
  cursor: pointer;
}

.usericon {
  display: inherit;
  max-height: 46px;
  position: relative;
  padding: 7px 3px;
  cursor: pointer;
}
.downarrow {
  margin-left: 5px;
}
.UserLoginBox ul {
  margin: 0;
  list-style: none;
  position: absolute;
  right: 0;
  top: 55px;
  padding: 0;
  display: none;
  width: 204px;
  border: 1px solid #dedede;
  background: #fff;
  z-index: 1305 !important;
  border-top: 0;
}

.UserLoginBox:hover ul {
  display: block;
}
.UserLoginBox ul li {
  background: #f9f9f9;
  font-size: 14px;
  color: #322a7d;
  text-align: left;
}

.UserLoginBox ul li a {
  color: #322a7d;
  text-decoration: none;
  padding: 10px 17px;
  display: block;
}
.UserLoginBox ul li.logout {
  padding: 10px 17px 10px 17px;
  cursor: pointer;
  color: #2c405a;
  text-transform: uppercase;
  font-weight: bold;
}
.UserLoginBox ul li a:hover {
  color: #2c405a;
}

.submenu {
  height: 45px;
  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.05);
  border-bottom: 0.5px solid #cfcfcf;
  background: #fff;
  padding-left: 30px;
  padding-right: 30px;
}
.submenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.submenu ul li {
  float: left;
}
.submenu ul li a {
  font-size: 14px;
  line-height: 32px;
  padding: 5px 0;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin: 0 30px 0 0;
  position: relative;
  cursor: pointer;
  color: #7f8fa4;
  display: block;
}
.submenu ul li a.active {
  color: #00ab5b;
  position: relative;
}

.submenu ul li.active a {
  color: #00ab5b;
}
.paddingbox {
  padding: 0 50px 50px 50px;
}
.containerbox {
  margin-top: 33px;
  border-radius: 4px;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ececec;
  background-color: #fff;
  padding: 15px;
}
.containerbox h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding: 15px 0 20px 0px;
}
.form .primary-button {
  color: #fff;
  background-image: linear-gradient(to bottom, #00a65a, #17d27c);
  border: solid 1px #00a65a !important;
  border-radius: 2px;
  margin-left: 10px;
  padding: 6px 20px;
}
.form .sec-button {
  color: #000;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
  border: 1px solid #ccc !important;

  border-radius: 2px;
  margin-left: 5px;
  padding: 6px 20px;
}
.form .sec-button:focus,
.form .sec-button:hover {
  color: #000;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff);
}
.modal-dialog .secondary-button,
.form .secondary-button {
  color: #fff;
  background: #df0000;
  border: solid 1px #df0000 !important;
  border-radius: 0px !important;
  margin-left: 10px;
  padding: 6px 20px;
}
.rightbtn .primary-button {
  color: #fff;
  background-image: linear-gradient(to bottom, #00a65a, #17d27c);
  border-radius: 0px !important;
  margin-left: 10px;
  padding: 6px 20px;
  border: solid 1px #00a65a !important;
}
.rightbtn .default-button {
  color: #000;
  background: #fff;
  border: solid 1px #b8b8b8 !important;
  padding: 6px 20px;
  border-radius: 2px;
}
.rightbtn .default-button:hover {
  background: #fff;
  color: #000;
  border: solid 1px #b8b8b8;
}
.title {
  position: relative;
}
.rightbtn {
  position: absolute;
  right: 0;
  top: 0;
}

.paginationData {
  width: 100%;
  margin: 10px 0;
  text-align: center;
}
.paginationData .leftpage {
  float: left;
  width: 50%;
}
.paginationData .leftpage .showing {
  float: left;
  font-size: 12px;
  color: #7f8fa4;
  padding: 7px 0;
}
.paginationData .leftpage .showing span {
  color: #354052;
  padding: 0 5px;
  font-weight: bold;
}
.paginationData .pagination {
  float: right;
}
.paginationData .pagination li {
  display: inline-block;

  margin-right: 10px;
}
.paginationData .pagination li a {
  border: 1px solid #cfcfcf;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
  color: #949494;
  cursor: pointer;
}
.paginationData .pagination li.active a {
  background: #00ab5b;
  color: #fff;
  cursor: default;
}
.paginationData .pagination li.next,
.paginationData .pagination li.previous {
  display: none;
}
.rowperpage .form-group .form-label {
  display: inline-block;
  color: #a1b1c8;
  font-size: 13px;
}
.rowperpage .form-group .form-control {
  display: inline-block;
  width: 65px;
  margin-left: 10px;
  border: 0px;
}
table th {
  border: 1px solid #cfcfcf;
  text-align: center;
  padding: 8px 5px;
  font-weight: normal;
  font-size: 13px;
}
table td {
  font-size: 13px;
  text-align: center;
}

table td span.Active {
  color: #4caf50 !important;
}
table td span.Inactive {
  color: #df0000 !important;
}
.form {
  padding: 20px 0px;
}
.form-group.row {
  margin-bottom: 0px !important;
}
.opacity {
  opacity: 0.2;
}
.input-error {
  color: #df0000;
}
.input-error-text {
  margin: 3px 0 0 1px;
  line-height: 1;
  font-size: 12px;
}
.form .form-control {
  width: 100%;
  border: 1px solid #b8b8b8;
  padding: 9.5px 13px;
  border-radius: 2px;
  color: #000;
  font-weight: normal;
  font-size: 15px;
  height: auto;
}
.form .row {
  margin-bottom: 20px;
}
.customer.form .row {
  margin-bottom: 20px;
  padding-bottom: 5px;
}
.containerboxform {
  margin-top: 33px;
  border-radius: 4px;
  box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ececec;
  background-color: #fff;
  padding: 15px 0;
}
.containerboxform h3 {
  font-size: 18px;
  font-weight: 500;
  color: #000;
  padding: 15px 0 20px 50px;
}

.bdrb {
  border-bottom: solid 1px #ececec;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;

  text-overflow: ellipsis;

  -webkit-appearance: none;

  width: 100%;
  border: 1px solid #b8b8b8;
  padding: 9.5px 13px;
  border-radius: 2px;
  color: #000;
  font-weight: normal;
  font-size: 15px;
  height: auto;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.select-search__input:not([readonly]):focus {
  cursor: initial;
}
.custom-file {
  z-index: 0;
}
.select-search__select {
  background: #fff;
}
.select-search__options {
  list-style: none;
  margin: 0;
  padding: 0;
}
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}
.select-search__option,
.select-search__not-found {
  display: block;
  min-height: 36px;
  height: auto;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}
.select-search--multiple .select-search__option {
  height: 48px;
}
.select-search__option.is-selected {
  background: #1c293b;
  color: #fff;
}
.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}
.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #1c293b;
  color: #fff;
}
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}
.select-search.is-disabled {
  opacity: 0.5;
}
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}
.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}
.select-search--multiple .select-search__input {
  cursor: initial;
}
.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}
.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search__select {
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 0;
  left: 15px;
  border-radius: 3px;
  overflow: auto;
  max-height: 280px;
  border: 1px solid #ededed;
  box-shadow: 1px 3px 4px #ccc;
  margin-right: 15px;
}

.pagetitle .select-search__select {
  top: 44px;
  position: absolute;
  z-index: 2;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}
.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}
.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}
.modal-open .modal {
  z-index: 9999;
}
.modal .modal-content {
  border-radius: 0px;
}
.modal-content .modal-header {
  padding: 10px;
}
.modal-content .modal-header .modal-title {
  color: #2c405a;
  font-size: 18px;
  font-weight: normal;
}
#deleteRecord .modal-content .modal-header .modal-title {
  text-transform: uppercase;
  font-size: 14px;
}
#deleteRecord .modal-body p {
  font-size: 12px;
}
#deleteRecord .modal-content .modal-header .modal-title span {
  color: #ff5242;
}

#deleteRecord .btn-secondary {
  color: #fff;
  background: #df0000;
  border: solid 1px #df0000;
  border-radius: 2px;
  margin-left: 10px;
  padding: 6px 20px;
}
#deleteRecord .btn-primary {
  color: #fff;
  background-image: linear-gradient(to bottom, #00a65a, #17d27c);
  border: 0;
  border-radius: 2px;
  margin-left: 10px;
  padding: 6px 20px;
}
#searchRecord {
  width: 900px;
  max-width: 900px;
}
#delModal {
  width: 500px;
  max-width: 500px;
}
#TripItemDetailsModal {
  width: 80%;
  max-width: 80%;
}
#CustomerRecord {
  width: 90%;
  max-width: 90%;
}
.delete {
  cursor: pointer;
  margin-left: 20px;
}
.rowperpage {
  margin-left: 40px;
}
.rowperpage .form-group .form-label {
  width: 85px;
}
.successmessage {
  display: none;
  position: fixed;
  top: 60px;
  right: 0px;
  background: #1b961b;
  color: #fff;
  padding: 10px 15px;
  z-index: 9999;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
}
.errorMessage {
  display: none;
  position: fixed;
  top: 60px;
  right: 0px;
  background: #f00;
  color: #fff;
  padding: 10px 15px;
  z-index: 9999;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
}
.errorMessages {
  width: 100%;
}
.errorMessages ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.errorMessages ul li {
  color: red;
  font-size: 13px;
  margin-bottom: 10px;
}
.notifyMessage {
  display: none;
  position: fixed;
  top: 60px;
  right: 0px;
  background: rgb(182, 182, 23);
  color: #fff;
  padding: 10px 15px;
  z-index: 99999;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
}
.inpcalendar {
  background: url(./images/calendar.png);
  background-repeat: no-repeat;
  background-position: 95% center;
}
.inpcalendars {
  background: #f2f2f2 url(./images/calendar.png);
  background-repeat: no-repeat;
  background-position: 5% center;
  border: 0px !important;
  padding-left: 35px !important;
}
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}
.bold {
  font-weight: 600;
  padding-top: 12px;
}
.row.mrb20 {
  margin-top: 20px;
  margin-left: 10px;
}
.tableData thead th {
  font-weight: bold;
}
.tableData tbody td {
  vertical-align: middle;
  padding: 4px 3px;
}
.tableData tbody td span.consign {
  font-weight: bold;
  background: url(./images/move.svg);
  background-size: 20px;
  cursor: move;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  padding-left: 28px;
  line-height: 34px;
  background-position: center;
}

.timedata {
  width: 110px;
}
.qty {
  width: 60px;
}
.pd20 {
  padding-bottom: 20px;
}
.form .form-control.milesInput {
  width: 73px;
  margin-left: 20px;
}
.calcmiles {
  padding: 4px 20px;
  font-weight: bold;
  background: #fff;
  margin-left: 10px;
  font-size: 16px;
}
.totalvalue {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
#trailer,
#trailer2,
#driver2,
#driver {
  width: 180px;
}

#searchRecord .row label {
  font-size: 14px;
}
#searchRecord .row label.ltb {
  padding-top: 8px;
}
.paddingleft0 {
  padding-left: 0px !important;
}

#searchRecord .modal-header .close {
  padding: 1rem 1rem;
  margin: -2rem 0rem -1rem auto;
}
.paddingright0 {
  padding-right: 0px !important;
}
#searchRecord .modal-content .modal-header {
  padding: 10px;
  vertical-align: middle;
  align-items: center;
}
#searchRecord .modal-content .modal-header .form-control {
  width: 100%;
}
.cursor tr {
  cursor: pointer;
}
.react-datepicker-popper {
  z-index: 99 !important;
}
.mrt30 {
  margin-top: 30px;
}
.mrt30 td {
  text-align: left;
}
#searchRecord .form-label {
  font-weight: bold;
}
.pdbmrb {
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.cursor.table tbody tr.isActive {
  background: #a2bbd6;
}
.cursor {
  margin-top: 20px;
}
thead tr th {
  font-weight: bold;
}
.cursor thead tr th {
  font-weight: bold;
}
.tripno {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 5px;
  display: inline-block;
}

.dl-list .dl-item .item {
  background: #ededed;
  cursor: move;
  padding: 10px;
}
.subhide {
  display: none;
}
.subactive {
  display: table-row;
}
.subactive thead {
  width: 100%;
  font-weight: bold;
}
.cursorpoit {
  cursor: pointer;
}

.Tablegrid .listtopbar {
  width: 100%;
  background: #fff;
  padding: 14px 10px 14px 10px;
  font-size: 12px;
  color: #1c293b;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  border-bottom: 1px solid #dcdcdc;
}
.Tablegrid .listtopbar .srno {
  width: 5%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .action {
  width: 5%;
  padding: 0 5px;
}

.Tablegrid .listtopbar .stop {
  width: 5%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .type {
  width: 5%;
  padding: 0 5px;
}

.Tablegrid .listtopbar .order {
  width: 10%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .date {
  width: 14%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .date2 {
  width: 15%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .statusC {
  width: 17%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .location {
  width: 15%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .location2 {
  width: 15%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .status {
  width: 10%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .pkgtype {
  width: 15%;
  padding: 0 5px;
}

.Tablegrid .listtopbar .weight {
  width: 7%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .unitbox {
  width: 10%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .unitboxC {
  width: 15%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .qty {
  width: 7%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .notes {
  width: 10%;
  padding: 0 5px;
  text-align: left;
}
.Tablegrid .listtopbar .notess {
  width: 5%;
  padding: 0 5px;
  text-align: left;
}
.Tablegrid .listtopbar .info {
  width: 10%;
  padding: 0 5px;
}
.Tablegrid .listtopbar .desc {
  width: 10%;
  padding: 0 5px;
}

.Tablegrid .listdata .form-control {
  font-size: 12px;
}

.Tablegrid .listdata {
  width: 100%;
  padding: 15px 10px 10px 15px;
  font-size: 12px;
  color: #354052;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  border: 1px solid #fff;
}

.Tablegrid .listdata:nth-child(even) {
  background-color: #ededed;
}
.Tablegrid .listdata .srno {
  width: 5%;
  padding: 0 5px;
  display: none;
}
.Tablegrid .listdata .action {
  width: 5%;
  padding: 0 5px;
  word-break: break-word;
  text-align: center;
}
.Tablegrid .listdata .qty {
  width: 7%;
  padding: 0 5px;
}
.Tablegrid .listdata .stop {
  width: 5%;
  padding: 0 5px;
}

.Tablegrid .listdata .type {
  width: 5%;
  padding: 0 5px;
}
.Tablegrid {
  width: 100%;
}
.Tablegrid .listdata .order {
  width: 10%;
  padding: 0 5px;
}
.Tablegrid .listdata .date {
  width: 14%;
  padding: 0 5px;
}
.Tablegrid .listdata .date2 {
  width: 15%;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .statusC {
  width: 17%;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .pkgtype {
  width: 15%;
  padding: 0 5px;
}
.Tablegrid .listdata .unitbox {
  width: 10%;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .unitboxC {
  width: 15%;
  padding: 0 5px;
  position: relative;
}
.col-sm-3.flexinherit{
  flex: inherit;
  width: auto;
}

.Tablegrid .listdata .select-search__input{
  font-size: 12px;
}
.Tablegrid .listdata .location {
  width: 15%;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .location2 {
  width: 15%;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .status {
  width: 10%;
  padding: 0 5px;
}
.Tablegrid .listdata .reqdate {
  width: 15%;
  padding: 0 5px;
}

.Tablegrid .listdata .weight {
  width: 7%;
  padding: 0 5px;
}
.Tablegrid .listdata .notes {
  width: 10%;
  text-align: right;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .notess {
  width: 5%;
  text-align: right;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .info {
  width: 10%;
  text-align: left;
  padding: 0 5px;
  position: relative;
}
.Tablegrid .listdata .desc {
  width: 10%;
  padding: 0 5px;
}

.btndrag {
  margin-right: 10px;
  cursor: pointer;
}

.drag ul {
  margin: 0;
  padding: 0;
}
.drag ul li {
  cursor: move;
  display: block;
  padding: 20px 10px;
  background: white;
  border-bottom: solid 1px gray;
}
.btnData {
  width: 100%;
  padding: 20px 0;
  overflow: hidden;
}
.btnData .btnbox {
  width: 42%;
  float: left;
}
.row .centercheck {
  margin-top: 8px;
  margin-left: 5px;
}
.mrt25 {
  margin-top: 25px;
}
.btnbox .calcmilesbtn {
  margin-left: 5px;
}
.miletotal {
  display: inline-block;
  margin-left: 5px;
}
.vieworder {
  cursor: pointer;
  white-space: nowrap;
}
.vieworder img {
  margin-right: 7px;
}
.miletotal label {
  width: 62px;
  margin-left: 20px;
}
.miletotal .form-control {
  display: inline-block;
  width: 73px;
}
.btnData .databox {
  width: 57%;
  float: left;
  margin-left: 0px;
}
.databox .form-control {
  display: inline-block;
  width: 63px;
  margin-right: 10px;
  padding: 7px 13px;
}
.mrb30 {
  margin-bottom: 30px;
}
.default-input {
  width: 100%;
  border: 1px solid #cccccc;
}
.grpmile label {
  display: inline-block;
}
.grpmile .form-control {
  width: 120px;
}
.btnpandata {
  padding: 20px 20px;
  width: 100%;
}
.btndata2,
.btndata {
  float: left;

  width: 42%;
}
.btndata2 {
  width: 50%;
}
.mrle {
  margin-left: 170px;
}
.pdt {
  padding-top: 10px;
}
.form.tripbox .row .col-sm-12 .row {
  margin-bottom: 0px;
}
.location #location .select-search__value::after {
  content: '';
  border: 0;
  transform: none;
  background: url(./images/search.svg);
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  right: 6px;
  opacity: 0.4;
}
.btn-primary {
  color: rgb(255, 255, 255);
  background-image: linear-gradient(rgb(0, 166, 90), rgb(23, 210, 124));
  border: 0px !important;
  border-radius: 0px !important;
  border-color: #17d27c;
}
.primary-button:hover {
  opacity: 0.6 !important;
  background-color: #17d27c;
  border-color: #17d27c;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.primary-button:active,
.primary-button:focus {
  background-color: #17d27c !important;
  border-color: #17d27c !important;
  box-shadow: none;
  opacity: 0.6 !important;
}
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary {
  color: rgb(255, 255, 255);
  background-color: rgb(223, 0, 0) !important;
  border: 1px solid rgb(223, 0, 0) !important;
  border-radius: 2px;
}
.btn-secondary:active,
.btn-secondary:hover {
  opacity: 0.6;
  color: rgb(255, 255, 255);
  background-color: rgb(223, 0, 0) !important;
  border: 1px solid rgb(223, 0, 0) !important;
}
.viewtrip {
  color: #00ab5b;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
}
.viewtrip img {
  margin-right: 10px;
}

.accordion-header{
  font-size: 15px;
  font-weight: bold;
  background: #fff;
  border: 1px solid #ededed;
  padding: 8px;
}
.accordion-header button{
  border: 0;
  background: transparent;
  cursor: pointer;
  font-weight: bold;
}
.addCustomerbtn {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  padding: 10px 9px 9px;
  font-size: 15px;
  color: #fff;
  background: #00ab5b;
  font-weight: 400;
  border: 1px solid #00ab5b;
  min-width: 40px;
  position: absolute;
  right:15px;
  top: 0px;
  z-index: 99;
  text-align: center;
}
#CustomerRecord .submenu {
  display: none;
}
#CustomerRecord .paddingbox {
  padding: 0px;
}
#CustomerRecord .containerboxform {
  margin-top: 0px;
  box-shadow: none;
  border: 0px;
}
#CustomerRecord .containerboxform .csreset {
  display: none;
}

.userbox {
  padding: 20px;
}
.parentactive td {
  background-color: #7fbb9f;
  border: 1px solid #80cca9 !important;
}
.subactive td {
  background-color: #86c5a8;
  border: 1px solid #80cca9 !important;
}
.subactive thead th {
  border-bottom-width: 0px !important;
  border: 1px solid #80cca9 !important;
}
.subactive > td {
  padding: 0px !important;
}
.subactive > td > table {
  margin: 0px;
}
.addaddress p {
  margin: 0 0 5px 0;
}
.addaddress .default-input {
  resize: none;
}
#directionMap.modal-dialog {
  transform: none;
  max-width: 90%;
}
.autosuggest {
  position: relative;
}
.autosuggest .suggestionlist {
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #ededed;
  position: absolute;
  left: 0px;
  background: #fff;
  z-index: 999;
}
.autosuggest .suggestionlist ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.autosuggest .suggestionlist ul li {
  width: 100%;
  padding: 7px 15px;
  cursor: pointer;
  border-bottom: 1px solid #ededed;
}
#searchRecord .padding0 {
  padding: 0px;
}
.noresfound {
  padding: 20px;
  text-align: center;
  font-size: 16px;
}
.sec-button:disabled {
  color: #333333 !important;
}
.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  color: rgb(255, 255, 255);
  background-color: #17d27c !important;
  border: 0px;
  border-radius: 2px !important;
  border-color: #17d27c !important;
  box-shadow: none !important;
}
.btn-secondary:hover,
.btn-secondary {
  color: rgb(255, 255, 255);
  background-color: rgb(223, 0, 0) !important;
  border: 1px solid rgb(223, 0, 0) !important;
}
.moremenu {
  position: relative;
  margin-left: 25px;
  display: inline-block;
}
.moremenu img {
  cursor: pointer;
}
.moremenus ul {
  /* min-height: 200px; */
  z-index: 999;
}
.Tablegrid .listdata .type {
  position: relative;
}
.Tablegrid .listdata .type .locInfo,
.Tablegrid .listdata .notes .locInfo,
.Tablegrid .listdata .location2 .locInfo,
.Tablegrid .listdata .location .locInfo {
  position: absolute;
  right: 0px;
  top: -21px;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
  border-radius: 50%;
  text-transform: lowercase;
  background: #00a65a;
  color: #fff;
}
.Tablegrid .listdata .notes .locInfo {
  left: 36px;
  top: -10px;
}
.fulladdress {
  display: none;
  position: absolute;
  width: 200px;
  height: auto;
  padding: 10px;
  border: 1px solid #888787;
  color: #000;
  border-radius: 6px;
  bottom: 26px;
  background: #fff;
  right: -11px;
  text-align: left;
  text-transform: uppercase;
  box-shadow: 0px 5px 5px #ccc;
}

.fulladdress:after,
.fulladdress:before {
  top: 100%;
  left: 90%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.fulladdress:after {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #ffff;
  border-width: 8px;
  margin-left: -8px;
}
.fulladdress:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #888787;
  border-width: 9px;
  margin-left: -9px;
}
.fulladdress p {
  margin: 0 0 7px 0;
  font-weight: normal;
}
.fulladdress p strong {
  font-weight: 600;
}
.Tablegrid .listdata .type .locInfo .fulladdress {
  right: auto;
  left: 0px;
  width: 100px;
}

.Tablegrid .listdata .type .locInfo .fulladdress:after,
.Tablegrid .listdata .type .locInfo .fulladdress:before {
  top: 100%;
  right: 75%;
  left: auto;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.Tablegrid .listdata .type .locInfo .fulladdress:after {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #ffff;
  border-width: 8px;
  margin-left: -8px;
}
.Tablegrid .listdata .type .locInfo .fulladdress:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: #888787;
  border-width: 9px;
  margin-left: -9px;
}

.Tablegrid .listdata .type .locInfo:hover .fulladdress,
.Tablegrid .listdata .notes .locInfo:hover .fulladdress,
.Tablegrid .listdata .location2 .locInfo:hover .fulladdress,
.Tablegrid .listdata .location .locInfo:hover .fulladdress {
  display: block;
}

.addblock {
  position: relative;
}
.addressdata {
  position: absolute;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  top: 182px;
  left: 0px;
  border: 1px solid #ededed;
  background: #fff;
  z-index: 9999;
}
.addressdata ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.addressdata ul li {
  width: 100%;
  padding: 10px 15px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
.dbcontainer {
  margin-top: 33px;
  border-radius: 4px;
  min-height: calc(100vh - 150px);
  padding: 15px;
}
.dashboardMenu {
  width: 100%;
  display: flex;
  align-items: center;
}
.dashboardMenu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  width: 100%;
}
.dashboardMenu ul li {
  margin: 0 20px 20px;
  box-shadow: 0px 1px 7px #ccc;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: inline-block;
}
.dashboardMenu ul li a {
  color: #00ab5b;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  display: block;

  padding: 95px 0px 15px 0px;
  text-align: center;
}

.dashboardMenu ul li.customer {
  background: url(./images/customer.png);
  background-repeat: no-repeat;
  background-position: center top 20px;
  background-size: 67px;
}
.dashboardMenu ul li.trips {
  background: url(./images/tracking.png);
  background-repeat: no-repeat;
  background-position: center top 20px;
  background-size: 67px;
}
.dashboardMenu ul li.order {
  background: url(./images/order.png);
  background-repeat: no-repeat;
  background-position: center top 20px;
  background-size: 67px;
}
.dashboardMenu ul li.locationicon {
  background: url(./images/asset.png);
  background-repeat: no-repeat;
  background-position: center top 20px;
  background-size: 67px;
}

.clr {
  clear: both;
}
textarea.default-input {
  min-height: 200px;
}
.cursorpoit.minus,
.cursorpoit.plus {
  position: relative;
  font-size: 0px;
}
.table td.cursorpoit.plus::before {
  content: '';
  font-weight: bold;
  font-size: 15px;
  background: url(./images/plus.png);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  background-size: 14px;
  position: absolute;
  left: 9px;
  top: 16px;
}
.table td.cursorpoit.minus::before {
  content: '';
  font-weight: bold;
  font-size: 15px;
  background: url(./images/minus.png);
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  background-size: 14px;
  position: absolute;
  left: 9px;
  top: 16px;
}
.orderFilter {
  width: 100%;
  padding: 0 15px;
}

.orderFilter .select-search__input {
  padding: 5px 13px;
}
.orderFilter .col-form-label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
}
table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
  text-transform: uppercase;
  font-size: 11px;
}
.orderFilter .col-sm-12 {
  padding: 0px;
}
.norecord {
  text-align: center;
  width: 100%;
  padding: 20px 0;
  font-weight: bold;
}
.odrlist td.cursorpoit {
  width: 40px;
}
.downicon {
  cursor: pointer;
  color: #17d27c;
  background: url(./images/down-arrow.png);
  background-repeat: no-repeat;
  background-size: 15px;
  font-size: 0px;
  width: 25px;
  height: 25px;
  display: inline-block;
  background-color: white;
  border-radius: 30px;
  background-position: center;
}
.moremenus {
  position: relative;
  display: inline-block;
  width: 30px;
  text-align: center;
  margin: 0;
}
.moremenus ul {
  display: none;
  position: absolute;
  width: 180px;
  height: auto;
  margin: 0;
  padding: 0px;
  background: #fff;
  box-shadow: 0px 4px 4px #b3b3b34a;
  right: -6px;
  top: 20px;
  border: 1px solid #d8d8d8;
  list-style: none;
  text-align: left;
}

.itemSignature {
  font-size: 15px;
  padding: 0px 33px 15px;
  border-bottom: 1px solid #d8d8d8;
  text-transform: uppercase;
  position: relative;
  margin-left: -31px;
  margin-right: -31px;
}
.itemSignature span {
  font-weight: bold;
}
.itemVerified {
  position: absolute;
  right: 10px;
  top: 0;
}
#moreinfo {
  max-width: 700px;
}
.itemimage {
  width: 100%;
}
.itemimage ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.itemimage ul li {
  width: 100%;
  padding: 5px 0;
  position: relative;
}
.itemimage ul li .downicon {
  position: absolute;
  margin-left: 20px;
}
.itemVerified img {
  width: 20px;
  vertical-align: text-top;
  margin-left: 10px;
}
.moremenus ul:after,
.moremenus ul:before {
  bottom: 100%;
  right: 5%;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.moremenus ul:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.moremenus ul:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #d8d8d8;
  border-width: 9px;
  margin-left: -9px;
}
.moremenus:hover ul {
  display: block;
}
.moremenus ul li {
  cursor: pointer;
  padding: 11px 7px;
  border-bottom: 1px solid #ededed;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: bold;
}
.moremenus ul li:hover {
  background: rgb(0 202 109 / 13%);
}
.Tablegrid .listdata .notes.trp .locInfo {
  left: 24px;
  top: -10px;
}
.sendSMS {
  position: absolute;
  right: 4px;
  top: 4px;
}
.trackStatus {
  position: absolute;
  right: 4px;
  top: 4px;
}
.trackStatus2 {
  position: absolute;
  right: 4px;
  top: 4px;
}
.col-form-label .celc {
  font-weight: bold;
}
.changeChart {
  width: 100%;
}
.changeChart ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.changeChart ul li {
  padding: 4px 30px 4px 30px;
  background: #ededed;
  color: #000;
  float: left;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.changeChart ul li.active {
  background: #00ab5b;
  color: #fff;
}
.changeChart ul li.active::after {
  content: '';
  display: inline-block;
  transform: rotate(45deg);
  height: 15px;
  width: 6px;
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
  position: absolute;
  left: 11px;
  top: 7px;
}
.filetoupload {
  width: 100%;
  margin: 20px 0;
}
.filetoupload ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.filetoupload ul li {
  position: relative;
  width: 70px;
  height: 70px;
  border: 1px solid #ededed;
  margin: 0 15px 15px 0;
  float: left;
}
.filetoupload ul li .imgHolder {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: block;
}
.filetoupload ul li .imgName {
  font-size: 8px;
  position: absolute;
  top: 70px;
  background: #fff;
}
.filetoupload ul li img {
  max-width: 100%;
}
.filetoupload ul li .remove {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #bdbcbc;
  color: #000;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  right: -10px;
  top: -10px;
  border-radius: 50%;
}
.desending {
  background: url('./images/up-arrow.png');
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 14px;
  cursor: pointer;
}
.asscending {
  background: url('./images/down-arrow.png');
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 14px;
  cursor: pointer;
}
.hide {
  visibility: hidden;
  height: 0px;
}
.New,
.Received {
  background: rgb(214 197 255);
}
.Started,
.In.Transit {
  background: rgb(255 197 213);
}
.Invoiced {
  background: rgb(157 203 236);
}
.Complete,
.Delivered {
  background: #b1ffb1;
}
.drop {
  cursor: no-drop;
}
.sendSMS:disabled,
.trackStatus:disabled,
.trackStatus2:disabled {
  background: #17d27c url(./images/lock.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #000;
  background-color: #e0e0e0;
  border-color: #007bff !important;
  border: 1px solid #ededed !important;
}

.notfound {
  text-align: center;
  font-size: 22px;
  padding-top: 150px;
}
.custom {
  float: left;
}
.form .custom .form-control {
  width: 120px;
  padding: 7px 13px;
}
.form .custom .form-control:focus {
  box-shadow: none;
}
.grpmile.tripmile label {
  float: left;
}
.grpmile.tripmile .form-control {
  width: 120px;
  float: left;
}
.boxuint {
  float: left;
}

.Tablegrid .listdata .unitbox .select-search__select {
  position: absolute;
  z-index: 2;
  top: 45px;
  right: 0;
  left: auto;
  border-radius: 3px;
  overflow: auto;
  max-height: 280px;
  border: 1px solid #ededed;
  box-shadow: 1px 3px 4px #ccc;
  margin-right: 15px;
  min-width: 220px;
}

.form .inpcalendars.form-control {
  width: 100%;
  border: 1px solid #b8b8b8 !important;
}

.filetouploadDoc {
  width: 100%;
  margin: 20px 0;
}
.filetouploadDoc ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.filetouploadDoc ul li {
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid #a7a6a6;
  margin: 0 0 5px 0;
  padding: 4px 7px;
  display: flex;
  align-items: center;
}
.filetouploadDoc ul li .imgHolder {
  width: 50px;
  height: 50px;

  float: left;
  display: block;
}
.filetouploadDoc ul li .imgName {
  font-size: 11px;
  display: block;
  float: left;
  width: calc(100% - 70px);
  background: #fff;
}
.filetouploadDoc ul li img {
  max-width: 100%;
}
.filetouploadDoc ul li .remove {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #bdbcbc;
  color: #000;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  right: -10px;
  top: -10px;
  border-radius: 50%;
}
.floatnone {
  float: none;
}
.make-relative {
  position: relative;
}
.mTop15 {
  margin-top: 15px;
}
.mTop20 {
  margin-top: 20px;
}
.make-flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

#CreateModal {
  width: 800px;
  max-width: 800px;
}
.created {
  background: green;
  color: #fff;
  padding: 4px 15px;
  border-radius: 30px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  display: inline-block;
  width: 100px;
}
.created a:hover,
.created a {
  text-decoration: none;
  color: #fff;
}
.notcreated {
  background: gold;
  color: #000;
  padding: 4px 15px;
  border-radius: 30px;
  display: inline-block;
  width: 100px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
}
iframe {
  display: none;
}
.markerTitle {
  font-size: 15px;
  color: #17d27c;
}

.filetoupload_list {
  width: 91%;
  margin: 20px 0;
}
.filetoupload_list ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.filetoupload_list ul li {
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid #ededed;
  margin: 0 0 15px 0;
}

.filetoupload_list ul li .imgName {
  font-size: 16px;
  position: relative;

  padding: 7px 15px;
  display: block;
}
.filetoupload_list ul li img {
  max-width: 100%;
}
.filetoupload_list ul li .remove {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #bdbcbc;
  color: #000;
  text-align: center;
  line-height: 16px;
  cursor: pointer;
  right: -10px;
  top: -10px;
  border-radius: 50%;
}
.filetoupload_list ul li .downicon {
  position: absolute;
  right: 10px;
  top: 7px;
}
#modalID {
  width: 500px;
}

.context-menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px;
  width: 150px;
  height: auto;
  margin: 0;
  display: block;
  z-index: 123123123;
/* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;
}

.active {
  color: #00a65a;
}
.odrlist .p-sortable-column-icon{
  cursor: pointer;
}
.triptbl .cursor,
.odrlist .cursor{
  cursor: pointer;
  white-space: nowrap;
  vertical-align: middle;
}
.triptbl .cursor span,
.odrlist .cursor span{
  margin-left: 5px;
}
.triptbl .table thead th,
.odrlist .table thead th{
  vertical-align: middle;
}

.itemrow{
  margin-top: 20px;
}
.itemrow .col-md-2,
.itemrow .col-md-1{
  padding-left: 5px;
  padding-right: 5px;
}
.center-display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height {
  height:100%
}

.full-width {
  width: 100%;
}
.urgentbox{
  position: absolute;
    left: 256px;
    top: -34px;
}
.hidden {
  display: none;
}